import React from "react";
import {graphql} from "gatsby";
import Hero from "../components/Hero";
import SearchForm from "../components/SearchForm";
import WhitelabelSearchForm from "../components/WhitelabelSearchForm";
import PropertyList from "../components/PropertyList";
import ImageCopy from "../components/Core/ImageCopy";
import Copy from "../components/Core/Copy";
import ImageCopyLinkList from "../components/Core/ImageCopyLinkList";
import LinkList from "../components/Core/LinkList";
import VerticalImageCopy from "../components/Core/VerticalImageCopy";
import IconCopy from "../components/Core/IconCopy";
import USP from "../components/USP";
import CopyWithBackground from "../components/CopyWithBackground";
import CopyInColumns from "../components/CopyInColumns";
import QuickNavigation from "../components/QuickNavigation";
import NumberedList from "../components/NumberedList";
import BlogList from "../components/BlogList";
import ImageGridList from "../components/ImageGridList";
import PartnersList from "../components/PartnersList";
import Banner from "../components/Banner";
import FormImageBlock from "../components/Property/FormImageBlock";
import PropertyListExternal from "../components/PropertyListExternal";
import TrustPilot from "../components/Core/TrustPilot";

export default function singleBlogPage({data}) {
    const blog = data.blog.pageBuilder;

    const content = (blog || [])
        .map((c) => {
            let el = null;

            switch (c._type) {
                case "hero":
                    el = <Hero key={c._key} {...c} />;
                    break;
                case "searchForm":
                    el = <SearchForm key={c._key} {...c} />;
                    break;
                case "trustpilot":
                    el = <TrustPilot key={c._key} {...c} />;
                    break;
                case "whitelabelSearchForm":
                    el = <WhitelabelSearchForm key={c._key} {...c} />;
                    break;
                case "copy":
                    el = <Copy key={c._key} {...c} />;
                    break;
				        case "imageCopy":
                    el = <ImageCopy key={c._key} {...c} />;
                    break;
                case "formImageBlock":
                    el = <FormImageBlock key={c._key} {...c} />;
                    break;
                case "imageCopyLinkList":
                    el = <ImageCopyLinkList key={c._key} {...c} />;
                    break;
                case "verticalImageCopy":
                    el = <VerticalImageCopy key={c._key} {...c} />;
                    break;
                case "linkList":
                    el = <LinkList key={c._key} {...c} />;
                    break;
                case "propertyList":
                    el = <PropertyList key={c._key} {...c} />;
                    break;
                case "iconCopyBlock":
                    el = <IconCopy key={c._key} {...c} />;
                    break;
                case "uspBlock":
                    el = <USP key={c._key} {...c} />;
                    break;
                case "copyWithBackground":
                    el = <CopyWithBackground key={c._key} {...c} />;
                    break;
                case "copyInColumns":
                    el = <CopyInColumns key={c._key} {...c} />;
                    break;
                case "quickNavigation":
                    el = <QuickNavigation key={c._key} {...c} />;
                    break;
                case "numberedList":
                    el=<NumberedList key={c._key} {...c} />;
                    break;
                case "blogList":
                    el=<BlogList key={c._key} {...c} />;
                        break;
                case "imageGridList":
                    el=<ImageGridList key={c._key} {...c} />;
                    break;
                case "partnersList":
                    el=<PartnersList key={c._key} {...c} />;
                    break;
                case "banner":
                    el=<Banner key={c._key} {...c} />;
                    break;
                case "propertyListExternal":
                    el=<PropertyListExternal key={c._key} {...c} />;
                    break;
                default:
                    el = null;
            }
            return el;
        });


    return (
      <div>
        {content}
      </div>
      );
}

export const query = graphql`
	query($slug: String!) {
      blog: sanityBlogPost( slug: { current: { eq: $slug } }) {
            titleSEO
            descriptionSEO
            keywordsSEO
            noindex
            slug {
                current
            }
            pageBuilder {
                ... on SanityHero {
                    _key
                    _type
                    heading
                    tagline
                    id
                    searchForm {
                        heading
                        tagline
                        id
                        description
                        override
                        overrideDate
                        overrideNights
                        overrideGuests
                        color
                        scrollToForm
                        cottageFilter
                        lodgeFilter
                        apartmentFilter
                        petFilter
                        hottubFilter
                        beachFilter
                        gardenFilter
                        wifiFilter
                        parkingFilter
                        fireFilter
                        babyFilter
                        poolFilter
                        luxuryFilter
                        gamesFilter
                        pubFilter
                        secludedFilter
                        accessibleFilter
                        enclosedFilter
                    }
                    whitelabelSearchForm {
                        heading
                        tagline
                        id
                        redirectOption
                        description
                        override
                        overrideDate
                        overrideNights
                        overrideGuests
                        color
                        scrollToForm
                        vrSort
                        apartmentFilter
                        holidayHomesFilter
                        villaFilter
                        hotelFilter
                        lodgeFilter
                        petsFilter
                        wifiFilter
                        coastalFilter
                        luxuryFilter
                        poolFilter
                        hotTubFilter
                    }
                    alignText
                    image {
                        asset {
                            fluid(maxWidth: 1200) {
                                    ...GatsbySanityImageFluid
                            }
                        }
                    }
                }
                ... on SanityTrustpilot {
                    _key
                    _type
                }
                ... on SanitySearchForm {
                    _key
                    _type
                    heading
                    tagline
                    id
                    description
                    override
                    overrideDate
                    overrideNights
                    overrideGuests
                    color
                    scrollToForm
                    cottageFilter
                    lodgeFilter
                    apartmentFilter
                    petFilter
                    hottubFilter
                    beachFilter
                    gardenFilter
                    wifiFilter
                    parkingFilter
                    fireFilter
                    babyFilter
                    poolFilter
                    luxuryFilter
                    gamesFilter
                    pubFilter
                    secludedFilter
                    accessibleFilter
                    enclosedFilter
                }
                ... on SanityWhitelabelSearchForm {
                    _key
                    _type
                    heading
                    tagline
                    id
                    redirectOption
                    description
                    override
                    overrideDate
                    overrideNights
                    overrideGuests
                    color
                    scrollToForm
                    vrSort
                    apartmentFilter
                    holidayHomesFilter
                    villaFilter
                    hotelFilter
                    lodgeFilter
                    petsFilter
                    wifiFilter
                    coastalFilter
                    luxuryFilter
                    poolFilter
                    hotTubFilter
                }
                ... on SanityLinkList {
                    _key
                    _type
                    heading
                    subHeading
                    id
                    links {
                        label
                        slug 
                    }
                }
                ... on SanityImageCopyLinkList {
                    _key
                    _type
                    heading
                    id
                    imageCopyLinks {
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                        copy {
                            _rawCopy
                        }
                        copyColor {
                            hex
                        }
                        backgroundColor {
                            hex
                        }
                        imagePosition
                        link {
                            current
                        }
                        linkCopy
                    }
                }
                ... on SanityBanner {
                    _key
                    _type
                    url
                    label
                    id
                    content
                }
                ... on SanityFormImageBlock {
                    _key
                    _type
                    subHeading
                    heading
                    formCopy
                    id
                    icon {
                        alt
                        image {
                            asset {
                                url
                            }
                        }
                    }   
                    image {
                        alt
                        image {
                            asset {
                                fluid(maxWidth: 1200) {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                    }
                }
                ... on SanityCopyInColumns {
                    _key
                    _type
                    leftColumn {
                        _rawCopy
                    }
                    rightColumn {
                        _rawCopy
                    }
                }
            ... on SanityQuickNavigation {
                    _key
                    _type
                    leftColumn {
                        _rawCopy
                    }
                    rightColumn {
                        _rawCopy
                    }
                }
                ... on SanityNumberedList {
                    _key
                    _type
                    isOrderedList
                    id
                    textColor {
                        hex
                    }
                    linksColor {
                        hex
                    }
                    backgroundColor {
                        hex
                    }
                    cards {
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                        heading
                        copy {
                            _rawCopy
                        }
                    }
                }
                ... on SanityImageCopy {
                    _key
                    _type
                    copy {
                        _rawCopy
                    }
                    copyColor {
                        hex
                    }
                    backgroundColor {
                        hex
                    }
                    imagePosition
                    image {
                        alt
                        image {
                            asset {
                                fluid(maxHeight: 800) {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                    }
                }
                 ... on SanityImageGridList {
                    _key
                    _type
                    heading
                    subheading
                    id
                    grids {
                        heading
                        slug
                        location
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SanityPartnersList {
                    _key
                    _type
                    heading
                    id
                    cards {
                        text
                        url
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SanityPropertyList {
                    _key
                    _type
                    heading
                    tagline
                    cardType
                    id
                    properties {
                        name
                        slug {
					        current
				        }
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                        region
                        stars
                        numReviews
                        beds
                        duration
                        sleeps
                        pets
                        price 
                        amenities
                        description
                    }
                    cards {
                        name
                        url
                        region
                        description
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SanityPropertyListExternal {
                    _key
                    _type
                    heading
                    tagline
                    id
                    cards {
                        name
                        url
                        region
                        description
                        image {
                            alt
                            image {
                                asset {
                                    fluid(maxHeight: 800) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
                        }
                    }
                }
                ... on SanityBlogList {
					_key
					_type
					subtitle
					title
                    id
					blogposts {
						image {
                            alt 
                            image {
                                asset {
                                    fluid(maxWidth: 1000) {
                                        ...GatsbySanityImageFluid
                                    }
                                }
                            }
						}
						slug {
							current
						}
						title
					}
				}
                ... on SanityCopy {
                    _key
                    _type
                    _rawCopy
                } 
                ... on SanityIconCopyBlock {
                    _key
                    _type
                    heading
                    id
                    cta {
                        label
                        slug 
                    }
                    blocks {
                        title
                        text {
                            _rawCopy
                        }
                        image {
                            asset {
                                url
                            }
                        }
                    }
                }
                ... on SanityCopyWithBackground {
                _key
                _type
                    text {
                      _rawCopy
                    }
                    backgroundImage {
                        alt
                        image {
                            asset {
                                fluid(maxWidth: 2500) {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                    }
              }
                ... on SanityUspBlock {
                    _key
                    _type
                    usps {
                        icon {
                            asset {
                                url
                            }
                        }
                        copy
                    }
                }
                ... on SanityVerticalImageCopy {
                    _key
                    _type
                    copy {
                        _rawCopy
                    }
                    image {
                        alt
                        image {
                            asset {
                                fluid(maxWidth: 2500) {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                    }
                }
			}
		}
        regions: allSanityRegion {
			nodes {
				name
				id
				slug {
					current
				}
			}
		}
		settings: sanitySiteSettings(_id: {eq: "siteSettings"})  {

            description
            id
            keywords
            primaryColor {
                hex
            }
            secondaryColor {
                hex
            }
            title
            logo {
                asset {
                    fixed {
                        src
                    }
                }
            }
            favicon {
                asset {
                    fixed(height: 48, width: 48) {
                        src
                    }
                }
            }
			image {
				asset {
				fixed(height: 600, width: 1200) {
					src
				}
				}
			}
            signUpDisplay
			signUpBackgroundColor
			signUpHeading
			zapierEndpoint
        }
	}
`;